import React, { Component, useEffect, useState } from 'react';
import { Button } from 'antd';
import Router, { useRouter } from 'next/router';


export default function Custom404() {

  const router = useRouter();
  const [state, setState] = useState({
    seconds: 6,
  });

  const countdown = (n) => {
    if (n < 0) {
      router.push('/');
      return;
    }
    setState({
      ...state,
      seconds: n
    });
    setTimeout(() => {
       countdown( n - 1 ); 
    }, 1000);
  };

  useEffect(() => {
    // countdown(state.seconds - 1);

  }, []);


  return (
    <div className='container'>
      <style jsx>{`
        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #fff;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 9999;
        }
        .error-image {
          width: 230px;
          height: 247px;
          margin: 10px 0;
        }
        .go-back {
          width: 480px;
          background: #17494D;
          color: #fff;
          height: 48px;
          line-height: 48px;
          text-align: center;
          margin-top: 20px;
          cursor: pointer;
        }
        .back-txt {
          color: #639FCD;
        }
      `}</style>
      <img className='error-image' alt='error-img' src='https://cdn.igamie.com/static/404.jpg' />
      <h3>Something went wrong, Either this page does not exist or we ran into an issue, Please try again later.</h3>
      {/* <p>After <span className='back-txt'>{state.seconds}s back to home</span> </p> */}
      {/* <Button onClick={() => Router.push('/')} type='primary' ghost>Back Home</Button> */}
      <div onClick={() => Router.push('/')}  className='go-back'>Return to home page</div>
    </div>
  );
}